<template>
  <b-button type="is-light" :disabled="loading || liveVehicles.length === 0" @click="exportCsv">
    <b-icon icon="file-export" size="is-small" />
    <span>Export CSV</span>
  </b-button>
</template>

<script>
import { formatCSV } from '@helpers/csv'
import { downloadFile } from '@helpers/downloadFile'

export default {
  name: 'LiveVehicleExportCsv.vue',

  props: {
    fileName: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    liveVehicles: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((obj) =>
          ['name', 'number', 'gender', 'country', 'accessType', 'trackers', 'categoryName'].every(
            (key) => key in obj,
          ),
        ),
    },
  },

  methods: {
    exportCsv() {
      const data = this.liveVehicles.map((vehicle, index) => [
        index + 1,
        vehicle.accessType,
        vehicle.trackers[0]?.type,
        vehicle.trackers[0]?.number,
        vehicle.number,
        vehicle.name,
        vehicle.gender,
        vehicle.country,
        vehicle.categoryName,
        '',
      ])
      data.unshift([
        'Line',
        'Public/Private',
        'Type',
        'Device',
        'Number',
        'Name',
        'Gender',
        'Country',
        'Category',
        'Comment',
      ])

      downloadFile(formatCSV(data), 'csv', this.fileName)
    },
  },
}
</script>
