<template>
  <div class="columns is-multiline">
    <template v-if="disallowedResyncTrackerConfigs.length">
      <b-field
        :label="`${disallowedResyncTrackerConfigs.length} balises ne seront pas resynchronisées`"
        message="Une resynchronisation a été faite il y a moins de 3 minutes."
        class="column is-full mb-0"
      />
      <b-collapse :open="false" position="is-bottom">
        <template #trigger="props">
          <a :aria-expanded="props.open" class="ml-3">
            {{ !props.open ? 'Voir plus' : 'Voir moins' }}
          </a>
        </template>
        <b-taglist class="mb-0 column is-full">
          <b-tag
            v-for="trackerConfig in disallowedResyncTrackerConfigs"
            :key="trackerConfig.id"
            type="is-danger"
          >
            {{ trackerConfig.trackerNumber }}
          </b-tag>
        </b-taglist>
      </b-collapse>
    </template>

    <div v-if="isResyncInProgress && trackerBeingResynchronized" class="column is-full">
      Mise à jour de la balise <strong>{{ trackerBeingResynchronized.trackerNumber }}</strong> en
      cours...
      <b-progress :value="progress.value" type="is-success" show-value>
        {{ progress.label }}
      </b-progress>
      <template v-if="estimatedTimeInSecondes >= 1">
        Temps estimé: {{ estimatedTimeInSecondes }} seconde{{
          estimatedTimeInSecondes > 1 ? 's' : ''
        }}
      </template>
    </div>

    <b-field class="column is-full">
      <b-button v-if="isResyncInProgress" type="is-danger" @click="abortResync"> Annuler </b-button>
      <b-button
        v-else
        type="is-primary"
        :loading="loading"
        :disabled="disabledSubmit"
        @click="startResync"
      >
        Resynchroniser
      </b-button>
    </b-field>
  </div>
</template>

<script>
import { differenceInMinutes } from 'date-fns'
import { mapActions } from 'vuex'

const RESYNC_INTERVAL_IN_SECONDS = 1

export default {
  name: 'ResyncQueclinkTrackerConfigs',

  props: {
    queclinkTrackerConfigs: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      isResyncInProgress: false,
      resyncTimeoutId: null,
      estimatedTimeInSecondes: null,
      trackerBeingResynchronized: null,
      trackerConfigs: this.queclinkTrackerConfigs,
      queclinkTrackerConfigsResynchronized: [],
    }
  },

  beforeDestroy() {
    this.abortResync()
  },

  computed: {
    disabledSubmit() {
      return (
        this.loading || this.disallowedResyncTrackerConfigs.length === this.trackerConfigs.length
      )
    },

    progress() {
      return {
        value:
          (this.queclinkTrackerConfigsResynchronized.length / this.trackerConfigs.length) * 100,
        label: `${this.queclinkTrackerConfigsResynchronized.length}/${this.trackerConfigs.length}`,
      }
    },

    disallowedResyncTrackerConfigs() {
      return this.queclinkTrackerConfigs.filter(
        (config) =>
          config.synchronizedAt && differenceInMinutes(Date.now(), config.synchronizedAt) <= 3,
      )
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async startResync() {
      this.$emit('startResync')

      this.loading = true
      this.isResyncInProgress = true
      this.queclinkTrackerConfigsResynchronized = []
      this.estimatedTimeInSecondes = this.queclinkTrackerConfigs.length

      let resyncIndex = 0
      const startTime = performance.now()

      const delayResync = async () => {
        if (!this.isResyncInProgress || !this.trackerConfigs[resyncIndex]) {
          this.abortResync()

          return
        }

        this.trackerBeingResynchronized = this.trackerConfigs[resyncIndex]

        window.clearTimeout(this.resyncTimeoutId)

        this.queclinkTrackerConfigsResynchronized.push(
          await this.resync(this.trackerBeingResynchronized),
        )

        const elapsedTime = (performance.now() - startTime) / 1000
        const completed = this.queclinkTrackerConfigsResynchronized.length
        const remaining = this.trackerConfigs.length - completed
        const averageTimePerTracker = completed > 0 ? elapsedTime / completed : 1
        this.estimatedTimeInSecondes = Math.ceil(remaining * averageTimePerTracker)

        this.resyncTimeoutId = setTimeout(async () => {
          resyncIndex++
          await delayResync()
        }, RESYNC_INTERVAL_IN_SECONDS * 1000)
      }

      await delayResync()
    },

    abortResync() {
      this.$emit('endResync')

      if (this.resyncTimeoutId) {
        window.clearTimeout(this.resyncTimeoutId)
      }

      this.loading = false
      this.isResyncInProgress = false
      this.resyncTimeoutId = null
      this.trackerConfigs = []
      this.queclinkTrackerConfigsResynchronized = []
    },

    async resync(tracker) {
      try {
        const queclinkTrackerConfig = await this.$services.queclinkTrackerConfigService.resync(
          tracker.id,
        )

        this.$emit('onResync', queclinkTrackerConfig)

        return queclinkTrackerConfig
      } catch (err) {
        console.error(err)
        this.addToastMessage({
          text: `Tracker ${tracker.trackerNumber}: ${
            err.message || 'Une erreur interne est survenue.'
          } `,
          type: 'is-danger',
        })
      }
    },
  },
}
</script>
