<template>
  <div class="box columns is-multiline">
    <o-table
      class="column is-full"
      :loading="loading"
      :items="stageRoutes"
      :draggable="!selectedRoute || !selectedRoutes.length"
      :checkable="true"
      :checked-rows="selectedRoutes"
      @check="
        selectedRoutes = $event
        selectedRoute = null
      "
      @drop="
        (droppedOnRowIndex, _, row) =>
          $emit('updatePosition', { liveStageRoute: row, position: droppedOnRowIndex })
      "
    >
      <b-table-column v-slot="{ row }" field="name" label="Nom">
        <template v-if="!showRow(row)">
          {{ row.name }}
        </template>
        <template v-else-if="showRow(row)">
          <b-field
            :message="fields.name.error"
            :type="fields.name.error !== null ? 'is-danger' : ''"
          >
            <b-input v-model="fields.name.value" type="text" />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="color" label="Couleur" centered>
        <template v-if="!showRow(row)">
          <b-icon icon="square" size="is-medium" :type="`is-${row.color}`" />
        </template>
        <template v-else-if="showRow(row)">
          <b-field
            :message="fields.color.error"
            :type="fields.color.error !== null ? 'is-danger' : ''"
          >
            <b-dropdown v-model="fields.color.value" scrollable>
              <template v-if="fields.color.value" #trigger>
                <b-button
                  :label="getColorLabel(fields.color.value)"
                  :type="`is-${fields.color.value}`"
                  icon-right="chevron-down"
                />
              </template>
              <b-dropdown-item
                v-for="color in colors"
                :key="color.value"
                :value="color.value"
                :type="`is-${color.value}`"
              >
                <b-icon icon="square" size="is-medium" :type="`is-${color.value}`" />
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="points" label="Points" centered>
        {{ row.pathLength }}
      </b-table-column>
      <b-table-column v-slot="{ row }" label="">
        <div v-if="!showRow(row)" class="buttons are-small is-right">
          <b-button type="is-primary" @click="editRoute(row)">
            <b-icon icon="edit" />
          </b-button>
          <b-button type="is-danger" @click="toggleModal(row)">
            <b-icon icon="trash" />
          </b-button>
        </div>
        <div v-else-if="showRow(row)" class="buttons are-small is-right">
          <b-button type="is-success" @click="submit">
            <b-icon icon="check" />
          </b-button>
          <b-button type="is-warning" @click="selectedRoute = null">
            <b-icon icon="times" />
          </b-button>
        </div>
      </b-table-column>
    </o-table>
    <LiveStageRouteForm
      v-if="selectedRoutes.length"
      :loading="loading"
      class="column is-full"
      @submit="submitAll"
      @delete="$emit('deleteAll', selectedRoutes)"
    />

    <o-modal
      :title="modalTitle"
      :isOpen="!!selectedRoute && isOpenModal"
      @closeModal="toggleModal"
      @confirmModalAction="$emit('delete', selectedRoute)"
    >
      <template>
        La suppression d'une route est <strong>définitive</strong>. <br />
        Cela <strong>inclut la suppression de ses zones de vitesse</strong>.
      </template>
    </o-modal>
  </div>
</template>

<script>
import LiveStageRouteForm from './LiveStageRouteForm.vue'
import OTable from '@components/Table.vue'
import OModal from '@components/Modal.vue'
import { iconNames } from '@data/icons'
import { LIVE_STAGE_ROUTE_COLORS } from '@constants/liveStageRoute/color'

export default {
  name: 'LiveStageRouteTable',

  components: {
    LiveStageRouteForm,
    OTable,
    OModal,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    stageRoutes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedRoute: null,
      selectedRoutes: [],
      fields: {
        name: {
          value: '',
          error: null,
        },
        color: {
          value: '',
          error: null,
        },
      },
      isOpenModal: false,
    }
  },

  created() {
    this.colors = LIVE_STAGE_ROUTE_COLORS
    this.icons = iconNames
  },

  watch: {
    loading() {
      this.selectedRoute = null
      this.selectedRoutes = []
    },
  },

  computed: {
    modalTitle() {
      return `Suppression de la route ${this.selectedRoute && this.selectedRoute.name}`
    },
  },

  methods: {
    showRow(row) {
      return this.selectedRoute && !this.isOpenModal && row.id === this.selectedRoute.id
    },

    submit() {
      this.$emit('submit', {
        ...this.selectedRoute,
        name: this.fields.name.value,
        color: this.fields.color.value,
      })
    },

    submitAll(fields) {
      this.$emit(
        'submitAll',
        this.selectedRoutes.map((route) => ({ ...route, ...fields })),
      )
    },

    editRoute(route) {
      this.selectedRoutes = []
      this.selectedRoute = route
      this.fields = {
        name: {
          value: route.name,
          error: null,
        },
        color: {
          value: route.color,
          error: null,
        },
      }
    },

    toggleModal(route = null) {
      this.isOpenModal = !!route
      this.selectedRoute = route
    },

    getColorLabel(value) {
      const color = LIVE_STAGE_ROUTE_COLORS.find((color) => color.value === value)

      return color?.label || LIVE_STAGE_ROUTE_COLORS[0].label
    },
  },
}
</script>
