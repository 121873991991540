<template>
  <div class="is-flex is-flex-direction-column is-align-items-center">
    <o-message
      :closable="false"
      :errorMessages="errorImportMessages"
      title="Erreurs d'importation"
    />
    <import-csv
      v-show="showUploadButton"
      label="Import CSV"
      :format="columns"
      :loading="false"
      @submit="submit"
    />
  </div>
</template>

<script>
import { getErrorMessages, importLiveVehicleError } from '@helpers/importErrorMessages'
import ImportCsv from '@components/ImportCsv.vue'
import OMessage from '@components/Message.vue'

export default {
  name: 'LiveVehicleImportCsv',

  components: {
    OMessage,
    ImportCsv,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
    showUploadButton: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      columns: {
        line: 'Line',
        accessType: 'Public/Private',
        trackerType: 'Type',
        trackerNumber: 'Device',
        number: 'Number',
        name: 'Name',
        gender: 'Gender',
        country: 'Country',
        liveCategoryName: 'Category',
        comment: 'Comment',
      },
      errorImportMessages: [],
    }
  },

  methods: {
    async submit({ result, error }) {
      this.$emit('submit')
      this.errorImportMessages = []

      if (error !== null) {
        this.errorImportMessages = [error]
        this.$emit('error')
        return
      }

      const vehicles = result.map((vehicle) => ({
        ...vehicle,
        gender: vehicle.gender !== '' ? vehicle.gender : null,
        country: vehicle.country !== '' ? vehicle.country : null,
        comment: vehicle.comment !== '' ? vehicle.comment : null,
      }))

      try {
        const liveVehicles = await this.$services.liveVehicleService.saveAll(this.live.id, vehicles)
        this.$emit('done', liveVehicles)
      } catch (err) {
        this.errorImportMessages = getErrorMessages(err, importLiveVehicleError)
        this.$emit('error')
      }
    },
  },
}
</script>
