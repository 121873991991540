<template>
  <o-table
    :items="trackers"
    :loading="loading"
    :draggable="false"
    :checkable="true"
    :sticky-header="true"
    :is-row-checked="isRowChecked"
    :checked-rows="checkedQueclinkTrackerConfigs"
    @check="$emit('update:checkedQueclinkTrackerConfigs', $event)"
  >
    <b-table-column v-slot="{ row }" field="trackerNumber" label="Balise" sortable>
      <router-link
        :to="{
          name: 'queclinkTracker.edit',
          params: { id: row.id },
        }"
      >
        {{ row.trackerNumber }}
      </router-link>
    </b-table-column>
    <b-table-column v-slot="{ row }" field="vehicleNumber" label="Dossard" sortable>
      {{ row.vehicleNumber }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="interval" label="Intervalle en seconde" sortable>
      {{ row.interval ?? '–' }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="initializedAt" label="Initialisé" sortable>
      <template v-if="row.isInitialized">
        <b-icon size="is-small" type="is-success" icon="check" />
        Depuis le {{ format(row.initializedAt) }}
      </template>
      <template v-else>
        <b-icon size="is-small" type="is-danger" icon="times" />
      </template>
    </b-table-column>
    <b-table-column v-slot="{ row }" field="synchronizedAt" label="Synchronisé" sortable>
      <template v-if="row.isProcessing">
        <b-icon size="is-small" type="is-info" icon="hourglass-half" />
        En cours depuis le {{ format(row.synchronizedAt) }}
      </template>
      <template v-else-if="row.synchronizedAt">
        <b-icon size="is-small" type="is-success" icon="check" />
        Depuis le {{ format(row.synchronizedAt) }}
      </template>
      <template v-else>
        <b-icon size="is-small" type="is-danger" icon="times" />
      </template>
    </b-table-column>
    <b-table-column v-slot="{ row }" field="syncStatus" label="Status" sortable>
      {{ getStatus(row.syncStatus) }}
    </b-table-column>
  </o-table>
</template>

<script>
import { QUECLINK_TRACKER_CONFIG_SYNC_STATUS } from '@constants/queclinkTrackerConfig/syncStatus'
import { QUECLINK_TRACKER_TYPE } from '@constants/tracker/type'
import OTable from '@components/Table.vue'
import { format } from 'date-fns'

export default {
  name: 'QueclinkTrackerConfigTable',

  components: {
    OTable,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    liveVehicles: {
      type: Array,
      required: true,
    },
    queclinkTrackerConfigs: {
      type: Array,
      required: true,
    },
    checkedQueclinkTrackerConfigs: {
      type: Array,
      required: true,
    },
  },

  computed: {
    trackers() {
      const queclinkTrackerConfigs = this.queclinkTrackerConfigs.reduce(
        (configs, config) => ({ ...configs, [config.id]: config }),
        {},
      )

      const trackers = []
      for (const vehicle of this.liveVehicles) {
        if (vehicle.trackers.length === 0) {
          continue
        }

        for (const tracker of vehicle.trackers) {
          if (tracker.type !== QUECLINK_TRACKER_TYPE) {
            continue
          }

          trackers.push({
            ...queclinkTrackerConfigs[tracker.id],
            trackerNumber: tracker.number,
            vehicleNumber: vehicle.number,
          })
        }
      }

      return trackers
    },
  },

  methods: {
    getStatus(status) {
      const syncStatus = QUECLINK_TRACKER_CONFIG_SYNC_STATUS[status]

      return syncStatus ?? 'Aucune configuration'
    },

    format(date) {
      return format(date, 'dd/MM/yyyy HH:mm')
    },

    // prevent auto reset checked row on update rows (from Buefy)
    isRowChecked(checkedRow, row) {
      return checkedRow.id === row.id
    },
  },
}
</script>
