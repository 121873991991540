<template>
  <div>
    <b-field label="Actions">
      <b-select v-model="formAction">
        <option v-for="action in actions" :key="action.key" :value="action.key">
          {{ action.label }}
        </option>
      </b-select>
    </b-field>
    <div v-if="formAction === 'delete'">
      <hr />
      <b-field class="mt-4">
        <b-button type="is-primary" :loading="loading" :disabled="loading" @click="submit">
          Supprimer la sélection
        </b-button>
      </b-field>
    </div>
    <div v-else-if="formAction === 'update'">
      <b-field grouped group-multiline label="Champs à modifier">
        <p v-for="field in Object.values(this.fields)" :key="field.label" class="control">
          <b-button
            :type="field.show ? 'is-primary is-light' : ''"
            :label="field.label"
            @click="field.show = !field.show"
          >
          </b-button>
        </p>
      </b-field>
      <hr v-show="Object.values(this.fields).find((field) => field.show)" />
      <form @submit.prevent="submit">
        <b-field v-if="fields.color.show" label="Couleur">
          <b-field grouped group-multiline>
            <b-radio-button
              v-for="color in liveStageRouteColors"
              :key="color.value"
              v-model="fields.color.value"
              :native-value="color.value"
              :type="`is-${color.value}`"
            >
              {{ color.label }}
            </b-radio-button>
          </b-field>
        </b-field>
        <hr />
        <b-field class="mt-4">
          <b-button type="is-primary" :loading="loading" :disabled="disabledSubmit" @click="submit">
            Modifier la sélection
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
import { LIVE_STAGE_ROUTE_COLORS } from '@constants/liveStageRoute/color'

const UPDATE_ACTION = 'update'
const DELETE_ACTION = 'delete'

export default {
  name: 'LiveStageRouteForm',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      updates: [],
      formAction: UPDATE_ACTION,
      fields: {
        color: {
          label: 'Couleur',
          show: false,
          value: LIVE_STAGE_ROUTE_COLORS[0].value,
        },
      },
    }
  },

  created() {
    this.liveStageRouteColors = LIVE_STAGE_ROUTE_COLORS

    this.actions = {
      [UPDATE_ACTION]: { key: UPDATE_ACTION, label: 'Modifier' },
      [DELETE_ACTION]: { key: DELETE_ACTION, label: 'Supprimer' },
    }
  },

  watch: {
    formAction() {
      this.fields.updates = []
    },
  },

  computed: {
    disabledSubmit() {
      return this.loading || !Object.values(this.fields).find((field) => field.show)
    },
  },

  methods: {
    submit() {
      if (this.formAction === DELETE_ACTION) {
        this.$emit('delete')
        return
      }

      const fieldsEnabled = Object.keys(this.fields).reduce((fields, key) => {
        if (this.fields[key].show) {
          return { ...fields, [key]: this.fields[key].value }
        }

        return fields
      }, {})

      this.$emit('submit', fieldsEnabled)
    },
  },
}
</script>
