export const iconNames = [
  'ambulance',
  'angle-left',
  'angle-right',
  'arrow-right-arrow-left',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-rotate-left',
  'arrow-trend-up',
  'arrow-up',
  'ban',
  'bars',
  'battery-full',
  'battery-half',
  'bed',
  'bell',
  'beer',
  'bicycle',
  'bolt',
  'broom',
  'cactus',
  'calendar',
  'camera',
  'campground',
  'car',
  'character-0',
  'character-1',
  'character-2',
  'character-3',
  'character-4',
  'character-5',
  'character-6',
  'character-7',
  'character-8',
  'character-9',
  'character-a',
  'character-b',
  'character-c',
  'character-d',
  'character-e',
  'character-f',
  'character-g',
  'character-h',
  'character-i',
  'character-j',
  'character-k',
  'character-l',
  'character-m',
  'character-n',
  'character-o',
  'character-p',
  'character-q',
  'character-r',
  'character-s',
  'character-t',
  'character-u',
  'character-v',
  'character-w',
  'character-x',
  'character-y',
  'character-z',
  'chart-area',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'circle',
  'info-circle',
  'circle-notch',
  'circle-play',
  'circle-xmark',
  'clipboard-check',
  'clock',
  'comment-medical',
  'comments',
  'cookie',
  'copy',
  'ellipsis-vertical',
  'envelope',
  'eye',
  'eye-slash',
  'facebook',
  'feather',
  'file-export',
  'flag',
  'flag-checkered',
  'gas-pump',
  'tachometer',
  'ghost',
  'globe',
  'grid-2',
  'heart-pulse',
  'helicopter',
  'hiking',
  'home',
  'image',
  'instagram',
  'key',
  'left-right',
  'link',
  'linkedin',
  'location-arrow',
  'location-crosshairs',
  'lock',
  'magnifying-glass',
  'map',
  'map-marker',
  'map-marker-alt',
  'map-pin',
  'mars',
  'minus',
  'motorcycle',
  'mountain',
  'pause',
  'person-running',
  'photo-film',
  'plane',
  'play',
  'plus',
  'quad',
  'ranking-star',
  'right-from-bracket',
  'right-to-bracket',
  'road-circle-exclamation',
  'route',
  'ruler',
  'satellite-dish',
  'screwdriver-wrench',
  'square-dashed',
  'ssv',
  'star',
  'stopwatch',
  'tag',
  'thumbs-up',
  'tiktok',
  'trash',
  'exclamation-triangle',
  'truck',
  'truck-pickup',
  'up-right-from-square',
  'user',
  'user-check',
  'user-doctor',
  'user-lock',
  'user-slash',
  'users',
  'utensils',
  'venus',
  'venus-mars',
  'walking',
  'window-maximize',
  'wrench',
  'times',
  'x-twitter',
  'youtube',
]
