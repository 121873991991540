<template>
  <div class="box">
    <form novalidate="true" @submit.prevent="submit">
      <b-field
        label="Nom"
        :message="fields.name.error"
        :type="fields.name.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.name.value" type="text" />
      </b-field>
      <b-field
        label="Type d'accès"
        :message="fields.accessType.error"
        :type="fields.accessType.error !== null ? 'is-danger' : ''"
      >
        <b-field>
          <b-radio-button v-model="fields.accessType.value" native-value="public">
            <span>Public</span>
          </b-radio-button>
          <b-radio-button v-model="fields.accessType.value" native-value="private">
            <span>Privé</span>
          </b-radio-button>
        </b-field>
      </b-field>
      <b-field
        label="Icône"
        :message="fields.icon.error"
        :type="fields.icon.error !== null ? 'is-danger' : ''"
      >
        <b-field grouped group-multiline>
          <b-radio-button
            v-for="icon in icons"
            :key="icon"
            v-model="fields.icon.value"
            :native-value="icon"
          >
            <span>
              <o-icon :name="icon" />
            </span>
          </b-radio-button>
        </b-field>
      </b-field>
      <b-field
        label="Couleur"
        :message="fields.color.error"
        :type="fields.color.error !== null ? 'is-danger' : ''"
      >
        <b-field grouped group-multiline>
          <b-radio-button
            v-for="color in colors"
            :key="color.value"
            v-model="fields.color.value"
            :native-value="color.value"
            :type="`is-${fields.color.value}`"
          >
            {{ color.label }}
          </b-radio-button>
        </b-field>
      </b-field>
      <hr />
      <b-field
        label="Validation de CP"
        :message="fields.useCheckpoint.error"
        :type="fields.useCheckpoint.error !== null ? 'is-danger' : ''"
      >
        <b-radio-button v-model="fields.useCheckpoint.value" :native-value="true">
          <span>Oui</span>
        </b-radio-button>
        <b-radio-button v-model="fields.useCheckpoint.value" :native-value="false">
          <span>Non</span>
        </b-radio-button>
      </b-field>
      <hr />
      <b-field>
        <div class="buttons">
          <b-button native-type="submit" type="is-primary" :loading="loading">Modifier</b-button>
          <b-button
            type="is-danger"
            tag="router-link"
            :to="{ name: 'live.edit.category.list', params: { id: live.id } }"
          >
            Annuler
          </b-button>
        </div>
      </b-field>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { LIVE_CATEGORY_ICONS } from '@constants/liveCategory/icon'
import { LIVE_CATEGORY_COLORS } from '@constants/liveCategory/color'

export default {
  name: 'EditLiveEditCategoryTab',

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveCategoryId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      liveCategory: null,
      fields: {
        name: {
          value: '',
          error: null,
        },
        accessType: {
          value: '',
          error: null,
        },
        useCheckpoint: {
          value: true,
          error: null,
        },
        icon: {
          value: '',
          error: null,
        },
        color: {
          value: '',
          error: null,
        },
      },
    }
  },

  watch: {
    liveCategory: {
      handler: 'load',
    },
  },

  created() {
    this.icons = LIVE_CATEGORY_ICONS
    this.colors = LIVE_CATEGORY_COLORS
  },

  async mounted() {
    this.loading = true
    this.liveCategory = await this.$services.liveCategoryService.getById(this.liveCategoryId)
    this.loading = false
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async load() {
      this.fields.name.value = this.liveCategory.name
      this.fields.accessType.value = this.liveCategory.accessType
      this.fields.icon.value = this.liveCategory.icon
      this.fields.color.value = this.liveCategory.color
      this.fields.useCheckpoint.value = this.liveCategory.useCheckpoint
    },

    async submit() {
      this.loading = true
      try {
        this.liveCategory = await this.$services.liveCategoryService.update(this.liveCategory.id, {
          name: this.fields.name.value,
          accessType: this.fields.accessType.value,
          icon: this.fields.icon.value,
          color: this.fields.color.value,
          useCheckpoint: this.fields.useCheckpoint.value,
        })
        this.addToastMessage({
          text: `La catégorie "${this.liveCategory.name}" a été modifié.`,
          type: 'is-success',
        })
      } catch (err) {
        console.error(err)
      }
      this.loading = false
    },
  },
}
</script>
