<template>
  <b-dropdown ref="dropdown" aria-role="list">
    <template #trigger="{ active }">
      <b-tooltip :label="tooltipLabel" position="is-right">
        <b-button
          class="is-success"
          label="Importer un GPX"
          type="is-primary"
          :icon-right="active ? 'angle-up' : 'angle-down'"
          :disabled="disabledTrigger"
        />
      </b-tooltip>
    </template>
    <b-dropdown-item custom aria-role="menuitem">
      <b-field label="Sélectionner les ressources pour l'import">
        <div class="columns is-multiline is-gapless">
          <b-checkbox
            v-for="item in checkboxItems"
            :key="item.id"
            v-model="resources"
            class="column is-full mt-2"
            expanded
            :native-value="item.id"
            :disabled="item.disabled || loading"
          >
            {{ item.label }}
          </b-checkbox>
        </div>
      </b-field>
      <hr />
      <o-upload
        v-model="gpxFile"
        :loading="loading"
        :type="fileType"
        label="Importer un GPX"
        :disabled="resources.length === 0"
        @submit="submit"
        @deleteFile="gpxFile = null"
      />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { STAGE_ACCESS_TYPE_PRIVATE, STAGE_ACCESS_TYPE_PUBLIC } from '@constants/stage/accessType'
import OUpload from '@components/Upload.vue'
import { mapActions } from 'vuex'

const RESOURCE_PATH = 'path'
const RESOURCE_PUBLIC_ROUTE = `${STAGE_ACCESS_TYPE_PUBLIC}-route`
const RESOURCE_PRIVATE_ROUTE = `${STAGE_ACCESS_TYPE_PRIVATE}-route`

export default {
  name: 'UploadLiveStageResources',

  components: {
    OUpload,
  },

  props: {
    liveStageId: {
      type: String,
      required: true,
    },
    stagePaths: {
      type: Array,
      required: true,
    },
    publicStageRoutes: {
      type: Array,
      required: true,
    },
    privateStageRoutes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      gpxFile: null,
      resources: [RESOURCE_PATH, RESOURCE_PUBLIC_ROUTE, RESOURCE_PRIVATE_ROUTE],
    }
  },

  created() {
    this.fileType = 'application/gpx+xml'
  },

  watch: {
    stagePaths: {
      handler(paths) {
        if (paths.length) {
          this.resources = this.resources.filter((r) => r !== RESOURCE_PATH)
        }
      },
      immediate: true,
    },

    publicStageRoutes: {
      handler(routes) {
        if (routes.length) {
          this.resources = this.resources.filter((r) => r !== RESOURCE_PUBLIC_ROUTE)
        }
      },
      immediate: true,
    },

    privateStageRoutes: {
      handler(routes) {
        if (routes.length) {
          this.resources = this.resources.filter((r) => r !== RESOURCE_PRIVATE_ROUTE)
        }
      },
      immediate: true,
    },
  },

  computed: {
    tooltipLabel() {
      return this.disabledTrigger
        ? "Impossible d'importer, des parcours et routes sont déjà existants."
        : 'Pour toutes les ressources sélectionnées (parcours/route)'
    },

    disabledTrigger() {
      return (
        this.stagePaths.length > 0 &&
        this.publicStageRoutes.length > 0 &&
        this.privateStageRoutes.length > 0
      )
    },

    checkboxItems() {
      return [
        {
          id: RESOURCE_PUBLIC_ROUTE,
          label: 'Parcours public',
          disabled: this.publicStageRoutes.length > 0,
          action: async () => {
            const publicRoutes = await this.$services.liveStageRouteService.create(
              this.liveStageId,
              STAGE_ACCESS_TYPE_PUBLIC,
              this.gpxFile,
            )

            this.$emit('update:publicStageRoutes', publicRoutes)
          },
        },
        {
          id: RESOURCE_PRIVATE_ROUTE,
          label: 'Parcours privé',
          disabled: this.privateStageRoutes.length > 0,
          action: async () => {
            const publicRoutes = await this.$services.liveStageRouteService.create(
              this.liveStageId,
              STAGE_ACCESS_TYPE_PRIVATE,
              this.gpxFile,
            )

            this.$emit('update:privateStageRoutes', publicRoutes)
          },
        },
        {
          id: RESOURCE_PATH,
          label: 'Route complète',
          disabled: this.stagePaths.length > 0,
          action: async () => {
            const stagePaths = await this.$services.liveStagePathService.replace(
              this.liveStageId,
              this.gpxFile,
            )

            this.$emit('update:stagePaths', stagePaths)
          },
        },
      ]
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true

      try {
        await Promise.all(
          this.checkboxItems
            .filter((item) => this.resources.includes(item.id))
            .map((item) => item.action()),
        )

        this.$refs.dropdown.toggle()
        this.gpxFile = null

        this.addToastMessage({
          text: `Le fichier gpx a bien été importé.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>
