export const LIVE_STAGE_WAYPOINT_COLORS = [
  { value: 'blue', label: 'Bleu' },
  { value: 'brown', label: 'Marron' },
  { value: 'gray', label: 'Gris' },
  { value: 'green', label: 'Vert' },
  { value: 'orange', label: 'Orange' },
  { value: 'pink', label: 'Rose' },
  { value: 'purple', label: 'Violet' },
  { value: 'red', label: 'Rouge' },
  { value: 'turquoise', label: 'Turquoise' },
]
