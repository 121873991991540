<template>
  <div class="box">
    <h2 class="title is-6">Modification des configurations</h2>
    <b-message v-if="outdatedTrackerConfigs.length" type="is-danger">
      <template v-if="outdatedTrackerConfigs.length > 1">
        {{ outdatedTrackerConfigs.length }} balises non synchronisées
      </template>
      <template v-else> {{ outdatedTrackerConfigs.length }} balise non synchronisée </template>
    </b-message>
    <b-message v-else type="is-success"> Toutes les balises sont synchronisées </b-message>
    <queclink-tracker-config-table
      :loading="loading"
      :live-vehicles="liveVehicles"
      :queclink-tracker-configs="queclinkTrackerConfigs"
      :checked-queclink-tracker-configs.sync="checkedQueclinkTrackerConfigs"
    />
    <template v-if="checkedQueclinkTrackerConfigs.length">
      <hr />
      <b-field label="Action">
        <b-select v-model="formAction">
          <option value="update">Modifier la synchronisation</option>
          <option value="resync">Resynchroniser</option>
        </b-select>
      </b-field>
      <edit-queclink-tracker-config-form
        v-if="formAction === 'update'"
        :loading="loading"
        @submit="submitAll"
      />
      <resync-queclink-tracker-configs
        v-if="formAction === 'resync'"
        :queclink-tracker-configs="checkedQueclinkTrackerConfigs"
        @startResync="startResync"
        @endResync="endResync"
        @onResync="onResync"
      />
    </template>
  </div>
</template>

<script>
import EditQueclinkTrackerConfigForm from './EditQueclinkTrackerConfigForm.vue'
import ResyncQueclinkTrackerConfigs from './ResyncQueclinkTrackerConfigs.vue'
import QueclinkTrackerConfigTable from './QueclinkTrackerConfigTable.vue'
import { mapActions } from 'vuex'

const LOAD_INTERVAL_IN_SECONDS = 5

export default {
  name: 'ListQueclinkTrackerConfigs',

  components: {
    EditQueclinkTrackerConfigForm,
    ResyncQueclinkTrackerConfigs,
    QueclinkTrackerConfigTable,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveVehicles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      formAction: 'update',
      loadIntervalId: null,
      queclinkTrackerConfigs: [],
      checkedQueclinkTrackerConfigs: [],
    }
  },

  async mounted() {
    this.loading = true
    await this.load()
    this.loading = false

    this.startLoadPolling()
  },

  beforeDestroy() {
    this.stopLoadPolling()
  },

  computed: {
    outdatedTrackerConfigs() {
      return this.queclinkTrackerConfigs.filter(
        (config) => !config.isSynchronized || !config.isInitialized,
      )
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async load() {
      this.queclinkTrackerConfigs =
        await this.$services.queclinkTrackerConfigService.getAllByLiveId(this.live.id)

      this.checkedQueclinkTrackerConfigs = this.checkedQueclinkTrackerConfigs.map((config) => ({
        ...config,
        ...(this.queclinkTrackerConfigs.find((trackerConfig) => trackerConfig.id === config.id) ||
          {}),
      }))
    },

    async submitAll(fields) {
      this.loading = true

      this.stopLoadPolling()

      const queclinkTrackerConfigs = this.checkedQueclinkTrackerConfigs.map(
        (queclinkTrackerConfig) => ({ ...queclinkTrackerConfig, ...fields }),
      )

      try {
        await this.$services.queclinkTrackerConfigService.updateAll(queclinkTrackerConfigs)

        this.checkedQueclinkTrackerConfigs = []

        this.addToastMessage({
          text: 'Configurations mises à jour',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      await this.load()
      this.startLoadPolling()

      this.loading = false
    },

    startLoadPolling() {
      this.stopLoadPolling()

      this.loadIntervalId = window.setInterval(async () => {
        await this.load()
      }, LOAD_INTERVAL_IN_SECONDS * 1000)
    },

    stopLoadPolling() {
      if (this.loadIntervalId) {
        window.clearInterval(this.loadIntervalId)
      }
    },

    startResync() {
      this.stopLoadPolling()
    },

    endResync() {
      this.startLoadPolling()

      if (!this.checkedQueclinkTrackerConfigs.length) {
        this.formAction = 'update'
      }
    },

    onResync(trackerConfig) {
      this.checkedQueclinkTrackerConfigs = this.checkedQueclinkTrackerConfigs.filter(
        (config) => config.id !== trackerConfig.id,
      )
      this.queclinkTrackerConfigs = this.queclinkTrackerConfigs.map((config) =>
        config.id === trackerConfig.id ? trackerConfig : config,
      )
    },
  },
}
</script>
